import { Box, Flex } from "@chakra-ui/react";
import { Button } from "react-scroll";
import styled from "styled-components";
import loginImg from "../../assets/heroBg.png";

export const MainBody = styled.div`
  font-family: "Montserrat";
  font-size: "18px";
  @media screen and (max-width: 992px) {
    font-size: "17px";
  }
  @media screen and (max-width: 480px) {
    font-size: "16px";
  }
`;

export const GradientButton = styled.button`
  top: 20px;
  left: 1129px;
  width: 98px;
  height: 45px;
  /* UI Properties */
  background: transparent linear-gradient(224deg, #c0e80d 0%, #a7af33 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #a7af33;
  border-radius: 8px;
  opacity: 1;
  color: #fff;
  border: 1px solid #a7af33;
  font-weight: bold;
  &:hover {
    background: #e9e4f2;
    color: #a7af33;
  }
`;

export const ScrollButton = styled(Button)`
  top: 20px;
  left: 1129px;
  width: 98px;
  height: 45px;
  /* UI Properties */
  background: transparent linear-gradient(224deg, #c0e80d 0%, #a7af33 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #a7af33;
  border-radius: 8px;
  opacity: 1;
  color: #fff;
  border: 1px solid #a7af33;
  font-weight: bold;
  &:hover {
    background: #e9e4f2;
    color: #a7af33;
  }
`;

export const MainNav = styled.nav`
  background: transparent;
  ${"" /* position: sticky; */}
  ${"" /* top: 0; */}
  z-index: 10;
  color: #fff;
  ${
    "" /* @media screen and (max-width: 756px) {
    position: sticky;
    top: 0;
  } */
  }
`;

export const HeroContainer = styled(Box)`
  background: url(${loginImg});
  bg-position: center;
  bg-repeat: no-repeat;
  bg-size: cover;
`;

export const TextLogin = styled(Box)`
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 8.8); /* Black w/opacity/see-through */
  color: white;
  border-radius: 7px;
  z-index: 2;
  width: 100%;
`;

export const BgFlex = styled(Flex)`
  filter: blur(2px);

  -webkit-filter: blur(0px);
  overflow: "hidden";
`;

export const ExtraBoldText = styled.p`
  font-weight: bold;
  color: #eac55c;
`;
