import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Stack,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import React from "react";
import Logo from "../../utilities/components/Logo";
import { navLinks } from "../Data";
import {
  GradientButton,
  MainBody,
  MainNav,
  ScrollButton,
} from "./home.elements";
import { Link } from "react-scroll";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
const Linkd = [
  {
    name: "Home",
    to: "home",
  },
  {
    name: "Our Coffee",
    to: "ourcoffee",
  },
  {
    name: "Our Impact",
    to: "ourimpact",
  },
  {
    name: "Our Story",
    to: "story",
  },
];

const CustmostLink = ({ children }) => {
  console.log({ children });
  return (
    <NavLink
      px={2}
      py={1}
      rounded={"md"}
      style={({ isActive }) => ({
        padding: 5,
        color: isActive ? "white" : "white",
        borderBottom: isActive ? "2px solid red" : "0px",
        borderColor: "red",
        fontWeight: isActive ? "bold" : "normal",
      })}
      _hover={{
        textDecoration: "none",
        color: "white",
        // bg: useColorModeValue("#595555", "#595555"),
      }}
      to={"/" + children?.to}
    >
      {children?.name}
    </NavLink>
  );
};
const MainNavigation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <>
      <Box

      // boxShadow={"1px 2px 3px #c4c4c4"}
      // bg={"white"}
      >
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <Stack
              py="4%"
              display={{ base: "flex", md: "none" }}
              direction={{ base: "column", md: "row" }}
              spacing={"25px"}
              justify="center"
              align={"center"}
            >
              {" "}
              <Logo />
              {/* {Linkd.map((link) => (
                <CustmostLink
                  key={link?.to}
                  onClick={() => {
                    console.log(link?.to);
                  }}
                >
                  {link?.name}
                </CustmostLink>
              ))} */}
              {navLinks.map((link) => (
                <Box cursor={"pointer"}>
                  <NavLink
                    px={2}
                    py={1}
                    rounded={"md"}
                    style={({ isActive }) => ({
                      padding: 5,
                      color: "black",
                      borderBottom: isActive ? "2px solid red" : "0px",
                      borderColor: "black",
                      fontWeight: isActive ? "bold" : "normal",
                    })}
                    _hover={{
                      textDecoration: "none",
                      color: "white",
                      // bg: useColorModeValue("#595555", "#595555"),
                    }}
                    to={"/" + link?.to}
                  >
                    {link?.name}
                  </NavLink>
                </Box>
              ))}
              <Link to="contact" smooth={true}>
                <GradientButton> Contact Us</GradientButton>
              </Link>
            </Stack>
          </DrawerContent>
        </Drawer>
        <MainNav position={"sticky"} top={0} zIndex={1}>
          <Wrap
            px={{ base: "5%", md: "1%", lg: "5%" }}
            align={"center"}
            justify={"space-between"}
            py="9px"
          >
            <Link to="#">
              <Logo />
            </Link>
            <Stack
              display={{ base: "none", md: "flex" }}
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "25px", md: "15px", lg: "25px" }}
              justify="center"
              align={"center"}
              fontSize="18px"
            >
              {navLinks.map((link) => (
                <Box cursor={"pointer"}>
                  <NavLink
                    px={2}
                    py={1}
                    rounded={"md"}
                    style={({ isActive }) => ({
                      padding: 5,
                      color: isActive ? "white" : "white",
                      borderBottom: isActive ? "2px solid red" : "0px",
                      borderColor: "white",
                      fontWeight: isActive ? "bold" : "normal",
                    })}
                    _hover={{
                      textDecoration: "none",
                      color: "white",
                      // bg: useColorModeValue("#595555", "#595555"),
                    }}
                    to={"/" + link?.to}
                  >
                    {link?.name}
                  </NavLink>
                </Box>
              ))}
              {/* {Linkd.map((link) => (
                <CustmostLink key={link?.to}>{link?.name}</CustmostLink>
              ))} */}
            </Stack>
            <Box display={{ base: "none", md: "flex" }}>
              <Link to="contact" smooth={true}>
                <Button bg={"#ECC94B"}>Contact Us</Button>
              </Link>
            </Box>
            <Button
              display={{ base: "flex", md: "none" }}
              bg="transparent"
              onClick={onOpen}
            >
              <GiHamburgerMenu size="1.7rem" />
            </Button>
          </Wrap>
        </MainNav>
      </Box>
    </>
  );
};

export default MainNavigation;
