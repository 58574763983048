import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import OurCoffee from "../home/OurCoffee";

const Home = React.lazy(() => import("../home/Home"));
const OurCoffee = React.lazy(() => import("../home/OurCoffee"));
const OurImpact = React.lazy(() => import("../home/OurImpact"));
const Story = React.lazy(() => import("../home/Ourstory"));
const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/ourcoffee" element={<OurCoffee />} />
      <Route path="/ourimpact" element={<OurImpact />} />
      <Route path="/story" element={<Story />} />

      {/* <Route path="*" element={<Navigate to="/" />} /> */}
    </Routes>
  );
};

export default AllRoutes;
