import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import one from "../assets/Coffee bag final-min-min.png";
import two from "../assets/Free Coffee Bag Mockup PSD Template-min-min.png";
import three from "../assets/Kraft Paper Bag Back-min-min.png";
import four from "../assets/Kraft Paper Bag Mockup PSD Template-min-min.png";
import five from "../assets/Moyee Package 01 Back-min.png";
import six from "../assets/Coffee Bag Mockup Back 01-min.jpg";
import seven from "../assets/Coffee Bag Mockup Front 01-min.jpg";
import eight from "../assets/Free Coffee Bag Mockup PSD Template 2 Back-min.jpg";
import ten from "../assets/Free Coffee Bag Mockup PSD Template 2 Front-min.jpg";
import img1 from "../assets/1.png";
import img2 from "../assets/2.png";
import img3 from "../assets/3.png";
import img4 from "../assets/4.png";
const clientOne =
  "https://res.cloudinary.com/wow-importer/image/upload/v1667049692/moyee/companies/denmark_pftnnf.png";
const clientTwo =
  "https://res.cloudinary.com/wow-importer/image/upload/v1667049692/moyee/companies/radisson_lh9akf.png";
const clientThree =
  "https://res.cloudinary.com/wow-importer/image/upload/v1667049692/moyee/companies/airlines_uext1b.png";
const clientFour =
  "https://res.cloudinary.com/wow-importer/image/upload/v1667050050/moyee/companies/freshLand_qu7iw5.png";
const clientFive =
  "https://res.cloudinary.com/wow-importer/image/upload/v1667050050/moyee/companies/dagrofa_nqvlmo.png";
const clientSix =
  "https://res.cloudinary.com/wow-importer/image/upload/v1667050050/moyee/companies/hyatt_bkvumh.png";
const clientSeven =
  "https://res.cloudinary.com/wow-importer/image/upload/v1667050050/moyee/companies/blendstar_o1tz9x.png";

const nine =
  "https://res.cloudinary.com/wow-importer/image/upload/v1666705908/moyee/4_Free_Coffee_Bag_Mockup_PSD_Template_3-min_ajg3vw.jpg";

const eleven =
  "https://res.cloudinary.com/wow-importer/image/upload/v1666705908/moyee/Speciality_4_Free_Coffee_Bag_Mockup_PSD_Template_3-min_vaamf2.jpg";
// const fifteen =
//   "https://res.cloudinary.com/wow-importer/image/upload/v1669658437/moyee/photo_2022-11-28_20-59-29-min_vggb0b.jpg";
// const sixteen =
//   "https://res.cloudinary.com/wow-importer/image/upload/v1669658437/moyee/photo_2022-11-28_20-59-39-min_bbpmdm.jpg";

export const navLinks = [
  {
    name: "Home",
    to: "home",
  },
  {
    name: "Our Coffee",
    to: "ourcoffee",
  },
  {
    name: "Our Impact",
    to: "ourimpact",
  },
  {
    name: "Our Story",
    to: "story",
  },
];

export const socialMediaIcons = [
  {
    icon: <BsFacebook size="1.5rem" />,
    to: "https://www.facebook.com/moyeecoffee.et",
  },
  {
    icon: <BsInstagram size="1.5rem" />,
    to: "https://instagram.com/moyeecoffee_et?igshid=YmMyMTA2M2Y=",
  },
  {
    icon: <BsTwitter size="1.5rem" />,
    to: "https://www.linkedin.com/in/spotlight-ethiopia/",
  },
];

export const productsList = [
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,

  // fifteen,
  // sixteen,
];

export const clientsList = [
  clientOne,
  clientTwo,
  clientThree,
  clientFour,
  clientFive,
  clientSix,
  clientSeven,
];
export const circularimageList = [img1, img2, img3, img4];
export const ourCoffeeList = [one, two, four, five];
