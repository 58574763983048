import logo from "./logo.svg";
import "./App.css";
import AllRoutes from "./routes/AllRoutes";
import ScrollToTop from "./utilities/functions/ScrollToTop";
import { Suspense } from "react";
import { Center } from "@chakra-ui/react";
import { BarLoader } from "react-spinners";
import MainNavigation from "./home/components/MainNavigation";

function App() {
  let routes = <AllRoutes />;

  return (
    <>
      <ScrollToTop />
      {/* <MainNavigation /> */}
      <Suspense
        fallback={
          <Center alignSelf={"center"} minH="500px">
            <BarLoader size={16} color="#b1c523" />
          </Center>
        }
      >
        {routes}
      </Suspense>
    </>
  );
}

export default App;
